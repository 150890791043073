'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
var Mappy = require('../L.Mappy')

L.Control.Layers = module.exports = require('./Control').extend({
  options: {
    autoZIndex: true,
    traffic: true,
    trafficTooltip: true,
    publicTransport: true,
    publicTransportTooltip: true,
    viewMode: true,
    viewModeTooltip: true,
    bicycle: true,
    bicycleTooltip: true,
    trafficLegend: true
  },

  initialize: function (baseLayers, overlays, options) {
    L.setOptions(this, options)

    this._buttons = {}
    this.trafficLegend = null
    this._layers = {}
    this._lastZIndex = 0

    for (var i in baseLayers) {
      if (baseLayers.hasOwnProperty(i)) {
        this._addLayer(baseLayers[i], i)
      }
    }

    for (i in overlays) {
      if (overlays.hasOwnProperty(i)) {
        this._addLayer(overlays[i], i, true)
      }
    }
  },

  _addLayer: function (layer, name, overlay) {
    var id = L.stamp(layer)

    this._layers[id] = {
      layer: layer,
      name: name,
      overlay: overlay
    }

    if (this.options.autoZIndex && layer.setZIndex) {
      this._lastZIndex++
      layer.setZIndex(this._lastZIndex)
    }
  },

  onAdd: function (map) {
    this._container = this._getContainer('layers')

    this._initLayout()
    this._initActiveButton()

    map
      .on('layeradd', this._onLayerChange, this)
      .on('layerremove', this._onLayerChange, this)

    return this._container
  },

  onRemove: function (map) {
    map
      .off('layeradd', this._onLayerChange, this)
      .off('layerremove', this._onLayerChange, this)
    if (this.options.trafficLegend) {
      this.trafficLegend.hide()
    }
  },

  _initLayout: function () {
    if (this.options.traffic !== false) {
      this._buttons.traffic = this._createSwitch('<span>T</span>', this.options.trafficTooltip && Mappy.getText('layers.traffic'), null, 'traffic', this.activateTraffic, this.deactivateTraffic)
      this._addToolsBackgroundImage(this._buttons.traffic)
    }

    if (this.options.publicTransport !== false) {
      this._buttons.publicTransport = this._createSwitch('<span>TC</span>', this.options.publicTransportTooltip && Mappy.getText('layers.transport'), null, 'transport', this.activatePublicTransport, this.deactivatePublicTransport)
      this._addToolsBackgroundImage(this._buttons.publicTransport)
    }

    if (this.options.trafficLegend) {
      this.trafficLegend = L.control.TrafficLegend()
    }

    if (this.options.viewMode !== false) {
      var tooltip = L.DomUtil.create('div')
      var span = L.DomUtil.create('span', null, tooltip)
      var checkbox = this._createCheckbox(tooltip)
      var label = L.DomUtil.create('label', null, tooltip)
      this._buttons.hybrid = checkbox

      span.innerHTML = Mappy.getText('layers.photo')
      label.innerHTML = Mappy.getText('layers.hybrid')
      label.setAttribute('for', 'mappy-hybrid')

      this._buttons.aerial = this._createSwitch('<span>A</span>', this.options.viewModeTooltip && tooltip, null, 'aerial', function () {
        this._map.setViewmode('photo')
      }, function () {
        this._map.setViewmode('standard')
        this._map.removeOverlay('hybrid')
      }, 500)
      this._addToolsBackgroundImage(this._buttons.aerial)

      L.DomEvent
        .on(tooltip, 'mouseenter', this._onAerialTooltipMouseEnter, this)
        .on(tooltip, 'mouseleave', this._onAerialTooltipMouseLeave, this)
        .on(checkbox, 'click', this._onAerialLabelClick, this)
    }

    if (this.options.bicycle !== false) {
      this._buttons.bicycle = this._createSwitch('<span>B</span>', this.options.bicycleTooltip && Mappy.getText('layers.bicycle'), null, 'bicycle', this.activateBicycle, this.deactivateBicycle)
      this._addToolsBackgroundImage(this._buttons.bicycle)
    }
  },

  _initActiveButton: function () {
    var overlay = this._map.getTilelayer('overlay')
    if (overlay) {
      this._addOverlay(overlay.options.name)
    }
    this._updateViewMode(this._map.getTilelayer().options.name)
  },

  _onLayerChange: function (e) {
    var obj = this._layers[L.stamp(e.layer)]

    if (!obj) {
      return
    }

    var type = obj.overlay
      ? (e.type === 'layeradd' ? 'overlayadd' : 'overlayremove')
      : (e.type === 'layeradd' ? 'baselayerchange' : null)

    if (obj.overlay) {
      if (type === 'overlayremove') {
        this._removeOverlay(obj.name)
      } else {
        this._addOverlay(obj.name)
      }
    } else {
      this._updateViewMode(obj.name)
    }

    if (type) {
      this._map.fire(type, obj)
    }
  },

  _removeOverlay: function (name) {
    if (name === 'traffic') {
      if (this._buttons.traffic) {
        this.deactivateButton(this._buttons.traffic)
      }
      if (this.trafficLegend) {
        this.trafficLegend.hide()
      }
    } else if (name === 'public_transport' && this._buttons.publicTransport) {
      this.deactivateButton(this._buttons.publicTransport)
    } else if (name === 'bicycle' && this._buttons.bicycle) {
      this.deactivateButton(this._buttons.bicycle)
    } else if (name === 'hybrid' && this._buttons.hybrid) {
      this._buttons.hybrid.checked = false
    }
  },

  _addOverlay: function (name) {
    if (name === 'traffic') {
      if (this._buttons.traffic) {
        this.activateButton(this._buttons.traffic)
      }
      if (this.trafficLegend) {
        this.trafficLegend.show(this._map)
      }
    } else if (name === 'public_transport' && this._buttons.publicTransport) {
      this.activateButton(this._buttons.publicTransport)
    } else if (name === 'bicycle' && this._buttons.bicycle) {
      this.activateButton(this._buttons.bicycle)
    }

    if (this._buttons.hybrid && name === 'hybrid') {
      this._buttons.hybrid.checked = true
    }
  },

  _updateViewMode: function (name) {
    if (!this._buttons.aerial) {
      return
    }

    if (name === 'standard') {
      this.deactivateButton(this._buttons.aerial)
    } else {
      this.activateButton(this._buttons.aerial)
    }
  },

  activateTraffic: function (event) {
    this._map.setOverlay('traffic')
    if (this.trafficLegend) {
      this.trafficLegend.show(this._map)
    }
  },

  deactivateTraffic: function () {
    this._map.removeOverlay('traffic')
    if (this.trafficLegend) {
      this.trafficLegend.hide()
    }
  },

  activatePublicTransport: function () {
    this._map.setOverlay('public_transport')
  },

  deactivatePublicTransport: function () {
    this._map.removeOverlay('public_transport')
  },

  activateBicycle: function () {
    this._map.setOverlay('bicycle')
  },

  deactivateBicycle: function () {
    this._map.removeOverlay('bicycle')
  },

  // IE7 bugs out if you create a checkbox dynamically, so you have to do it this hacky way
  _createCheckbox: function (parent) {
    var html = '<input type="checkbox" name="mappy-hybrid-checkbox" id="mappy-hybrid" />'

    var htmlFragment = document.createElement('div')
    htmlFragment.innerHTML = html

    return parent.appendChild(htmlFragment.firstChild)
  },

  _onAerialTooltipMouseEnter: function (event) {
    clearTimeout(this._removing)
  },

  _onAerialTooltipMouseLeave: function (event) {
    this._closeTooltip('aerial', 500)
  },

  _onAerialLabelClick: function (event) {
    if (this._buttons.hybrid && this._buttons.hybrid.checked) {
      if (this._map.getTilelayer().options.name !== 'photo') {
        this._map.setViewmode('photo')
      }
      this._map.setOverlay('hybrid')
    } else {
      this._map.removeOverlay('hybrid')
    }
  },

  getTrafficLegend: function () {
    return this.trafficLegend
  }
})

L.control.layers= function (baseLayers, overlays, options) {
	return new L.Control.Layers(baseLayers, overlays, options);
}
