"use strict";

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null);
var Mappy = require("../L.Mappy");

module.exports = L.Control.extend({
  options: {
    position: "topright",
    singleToolBox: false,
  },

  _buttons: {},
  _tooltips: {},

  addTo: function (map) {
    this._buttons = {};
    this._tooltips = {};
    L.Control.prototype.addTo.call(this, map);
    L.DomUtil.addClass(this._container, "mappy-control");
    return this;
  },

  removeFrom: function (map) {
    var pos = this.getPosition();
    var corner = map._controlCorners[pos];

    if (!this.options.singleToolBox || this.options.singleToolBox) {
      corner.removeChild(this._container);
      this._container = null;
    }
    this._map = null;

    if (this.onRemove) {
      this.onRemove(map);
    }

    return this;
  },

  _createContainer: function (className) {
    this._container = L.DomUtil.create("div", "mappy-control-" + className);
    return this._container;
  },

  _getContainer: function (name) {
    if (!this.options.singleToolBox) {
      return L.DomUtil.create("div", "mappy-control-" + name);
    }
    return L.DomUtil.create("div", "mappy-control-main");
  },

  _createBasicButton: function (html, className) {
    var btn = L.DomUtil.create(
      "a",
      "mappy-button mappy-button-" + className,
      this._container
    );
    btn.innerHTML = html || "";
    btn.href = "#";

    L.DomEvent.on(btn, "mousedown", L.DomEvent.stop)
      .on(btn, "dblclick", L.DomEvent.stop)
      .on(btn, "click", L.DomEvent.stop);

    return btn;
  },

  _createButton: function (html, title, className, fn) {
    var btn = this._createBasicButton(html, className);
    this._initTooltip(title, className, btn);

    L.DomEvent.on(btn, "click", fn, this).on(
      btn,
      "click",
      this._refocusOnMap,
      this
    );

    return btn;
  },

  _addToolsBackgroundImage: function (el) {
    el.children[0].style.backgroundImage =
      "url('" + L.Mappy.getImgPath() + "tools-2x.png')";
    el.children[0].style.backgroundSize = "175px 72px";
  },

  _createSwitch: function (html, title, title2, className, fn, fn2, delay) {
    var btn = this._createBasicButton(html, className);
    this._initTooltip(title, className, btn, delay);

    L.DomEvent.on(
      btn,
      "click",
      function (evt) {
        if (!L.DomUtil.hasClass(btn, "mappy-button-active")) {
          this.activateButton(btn);
          this._updateTooltipContent(title2, className);
          fn.call(this, evt);
        } else {
          this.deactivateButton(btn);
          this._updateTooltipContent(title, className);
          fn2.call(this, evt);
        }
        this._refocusOnMap();
      },
      this
    );

    return btn;
  },

  _initTooltip: function (title, className, btn, delay) {
    if (!title) {
      return;
    }

    if (!Mappy.isTooltipEnabled()) {
      return;
    }

    L.DomEvent.on(
      btn,
      "mouseenter",
      function () {
        this._closeTooltips();
        this._createTooltip(title, className, btn);
      },
      this
    ).on(
      btn,
      "mouseleave",
      function () {
        this._closeTooltip(className, delay);
      },
      this
    );
  },

  _createTooltip: function (title, id, btn) {
    if (!this._tooltips[id]) {
      var tooltip = L.DomUtil.create(
        "div",
        "mappy-tooltip mappy-tooltip-" + id,
        this._container
      );
      if (typeof title === "string") {
        tooltip.innerHTML = title;
      } else {
        tooltip.appendChild(title);
      }

      this._tooltips[id] = tooltip;
    } else {
      this._container.appendChild(this._tooltips[id]);
    }

    this._tooltips[id].style.top =
      btn.offsetTop +
      (btn.offsetHeight - this._tooltips[id].offsetHeight) / 2 +
      "px";
  },

  _updateTooltipContent: function (title, id) {
    if (this._tooltips[id] && title) {
      if (typeof title === "string") {
        this._tooltips[id].innerHTML = title;
      } else {
        this._tooltips[id].appendChild(title);
      }
    }
  },

  _closeTooltip: function (id, delay) {
    this._removing = setTimeout(
      L.bind(function () {
        if (this._tooltips[id].parentNode) {
          this._container.removeChild(this._tooltips[id]);
        }
      }, this),
      delay || 0
    );
  },

  _closeTooltips: function () {
    clearTimeout(this._removing);
    for (var tooltip in this._tooltips) {
      if (this._tooltips[tooltip].parentNode) {
        this._tooltips[tooltip].parentNode.removeChild(this._tooltips[tooltip]);
      }
    }
  },

  activateButton: function (button) {
    L.DomUtil.addClass(button, "mappy-button-active");
    button.setAttribute("data-active", 1);
  },

  deactivateButton: function (button) {
    L.DomUtil.removeClass(button, "mappy-button-active");
    button.setAttribute("data-active", 0);
  },
});
