'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
var Mappy = require('../L.Mappy')
var debounce = require('./Util')

var mapSessionId = String(Math.random()).substring(2, 14)

module.exports = L.TileLayer.extend({
  options: {
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
  },

  tileUrl: "https://api-map.{domain}/map/1.0/slab/{m}/256/{z}/{x}/{y}?apikey={apikey}&sessionId={sessionId}",

  descrUrl: "https://api-map.{domain}/map/1.0/multi-descr/{m}/256/{z}/{t}?apikey={apikey}",

  descrInfos: {},

  attributions: [],
  layerItems: [],

  initialize: function (name, zIndex, options) {
    options = L.setOptions(this, L.extend({
      m: name,
      name: name,
      zIndex: zIndex,
      domain: Mappy._getDomain(),
      apikey: Mappy._getApikey(),
      sessionId: mapSessionId
    }, options))

    if (name !== 'photo' && options.detectRetina && L.Browser.retina) {
      this.options.m += '_hd'
    }

    L.TileLayer.prototype.initialize.call(this, this.tileUrl, options)
  },

  onAdd: function (map) {
    L.TileLayer.prototype.onAdd.call(this, map)
    this.on('load', this._onLoad)

    if (this.options.name === 'traffic') {
      this.interval = window.setInterval(L.bind(this.redraw, this), Mappy._refreshTraffic)
    }
  },

  onRemove: function (map) {
    L.TileLayer.prototype.onRemove.call(this, map)
    this.off('load', this._onLoad)

    if (this.options.name === 'traffic') {
      window.clearInterval(this.interval)
    }
  },

  getAttributions: function () {
    return this.attributions
  },

  _onLoad: debounce(function () {
    var bounds = this._map.getPixelBounds()
    var min = bounds.min.clone()
    var max = bounds.max.clone()

    // Retrieve tile x & y values.
    min = min.divideBy(this.options.tileSize)._floor()
    max = max.divideBy(this.options.tileSize)._floor()
    var tilesCoords = []

    // We got it !
    for (var x = min.x; x <= max.x; x++) {
      for (var y = min.y; y <= max.y; y++) {
        tilesCoords.push(x + ',' + y)
      }
    }

    if (tilesCoords.length > 0) {
      this._requestDescr(tilesCoords)
    }
  }, 500),

  _refreshAttributions: function (tiles) {
    if (!this._map) {
      return
    }
    this.attributions = []
    this.layerItems = []
    for (var i = 0; i < tiles.length; i++) {
      var key = this.options.m + '/' + this._getZoomForUrl() + '/' + tiles[i].replace(',', '/')
      if (this.descrInfos[key]) {
        for (var j = 0; j < this.descrInfos[key].copyrights.length; j++) {
          this.attributions.push(this.descrInfos[key].copyrights[j].name)
        }
        this.layerItems = this.layerItems.concat(this.descrInfos[key].items)
      }
    }
    this.fire('attributionsrefresh')
  },

  _requestDescr: function (tiles) {
    var zoomLevel = this._getZoomForUrl()

    // Define tiles to request
    var reqTiles = []
    for (var i = 0; i < tiles.length; i++) {
      var key = this.options.m + '/' + zoomLevel + '/' + tiles[i].replace(',', '/')
      if (!this.descrInfos[key]) {
        reqTiles.push(tiles[i])
      }
    }

    if (reqTiles.length > 0) {
      var requestUrl = L.Util.template(this.descrUrl, L.extend({
        z: zoomLevel,
        t: reqTiles.join(';')
      }, this.options))

      // Request multi-descr
      Mappy.cors({
        url: requestUrl
      }).then((response) => {
        for (var i = 0; i < response.length; i++) {
          this.descrInfos[this.options.m + '/' + response[i].sid] = response[i]
        }
        this._refreshAttributions(tiles)
      })
    }
  }
})
