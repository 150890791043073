"use strict"

var Mappy = require("./L.Mappy")
var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
var suggestTimeout

function check(obj, attr, message) {
  if (!obj.hasOwnProperty(attr)) {
    throw new Error(message)
  }
}

function requestGeocode(options) {
  Mappy._checkApikey()
  return Mappy.cors(options).then(function (response) {
    if (response && response.code) throw new Error(response)
    if (
      !response ||
      !response.addresses ||
      !response.addresses.features ||
      response.addresses.features.length === 0
    )
      throw new Error("no_result")
    return response.addresses.features
  })
}

function requestTransports(options) {
  options = options || {}
  var version = options.version
  var domain = options.domain
  delete options.version
  delete options.domain
  var data = L.extend(
    options,
    {
      lang: Mappy.getLocale(),
    },
    {
      from: coords(options.from),
      to: coords(options.to),
    },
    options
  )

  return Mappy.cors({
    url: "https://api-iti." + (domain || Mappy._getDomain()) + "/multipath/" + (version || Mappy._getRouteAPIVersion()) + "/transports",
    checkForAPIDepreciation: true,
    data,
  })
}

function requestRoute(options) {
  var data = L.extend(
    options || {},
    {
      lang: Mappy.getLocale(),
    },
    {
      from: coords(options.from),
      to: coords(options.to),
      providers: options.providers,
    },
    options
  )

  return Mappy.cors({
    url: "https://api-iti." + Mappy._getDomain() + "/multipath/" + Mappy._getRouteAPIVersion() + "/routes",
    checkForAPIDepreciation: true,
    data,
  }).then((response) => {
    if (!response || !response.routes || response.routes.length === 0) {
      throw new Error("no_result")
    }
    return response
  })
}

function coords(point) {
  function toArray() {
    if (point instanceof L.LatLng) {
      return [point.lng, point.lat]
    }
    return point.split(",").map(parseFloat)
  }

  return toArray(point)
    .map(function (n) {
      return n.toFixed(6)
    })
    .join(",")
}

module.exports = {
  localeParameters: {
    fr_FR: {
      favoriteCountry: 250,
      language: "fre",
    },
    en_GB: {
      favoriteCountry: 826,
      language: "eng",
    },
    fr_BE: {
      favoriteCountry: 56,
      language: "fre",
    },
    nl_BE: {
      favoriteCountry: 56,
      language: "dut",
    },
  },

  geocodeReverse: function (latLng, successCallback, failureCallback) {
    if (latLng instanceof Array) {
      latLng = L.latLng(latLng[0], latLng[1])
    }

    return requestGeocode({
      url: Mappy._getGeocodeReverse(),
      checkForAPIDepreciation: true,
      data: {
        x: latLng.lng,
        y: latLng.lat,
      },
    })
      .then((json) => {
        return successCallback ? successCallback(json) : json
      })
      .catch((error) => {
        if (failureCallback) return failureCallback(error)
        else throw error
      })
  },

  geocodeForward: function (query, successCallback, failureCallback) {
    return requestGeocode({
      url: Mappy._getGeocodeForward(),
      checkForAPIDepreciation: true,
      data: {
        limit: 10,
        q: query,
      },
    })
      .then((json) => {
        return successCallback ? successCallback(json) : json
      })
      .catch((error) => {
        if (failureCallback) return failureCallback(error)
        else throw error
      })
  },

  suggest: function (options, successCallback, failureCallback) {
    Mappy._checkApikey()
    return new Promise((resolve, reject) => {
      clearTimeout(suggestTimeout)
      var FranceBbox = "41.33367,-5.141593,51.088771,9.559831"
      var queryBbox = FranceBbox
      if (options && options.bbox instanceof L.LatLngBounds) {
        var sw = options.bbox.getSouthWest()
        var ne = options.bbox.getNorthEast()
        queryBbox = [sw.lat, sw.lng, ne.lat, ne.lng].join(",")
      }
      suggestTimeout = setTimeout(
        function requestSuggest() {
          Mappy.cors({
            url: Mappy._getSuggest(),
            checkForAPIDepreciation: true,
            data: {
              q: options && options.query,
              focus_bbox: queryBbox,
              filter: "geocoding",
            },
          })
            .then(function (json) {
              if (!json || !json.suggests) throw new Error("no_result")
              successCallback
                ? successCallback(json.suggests)
                : resolve(json.suggests)
            })
            .catch(function (error) {
              if (failureCallback) return failureCallback(error)
              else reject(error)
            })
        }.bind(this),
        250
      )
    })
  },

  route: function (options, successCallback, failureCallback) {
    Mappy._checkApikey()
    check(
      options,
      "from",
      "1st argument should contains from as string (lon,lat)"
    )
    check(options, "to", "1st argument should to as string (lon,lat)")
    check(
      options,
      "providers",
      "1st argument should contains providers as string"
    )

    return requestTransports(options)
      .then((response) => {
        return requestRoute(
          L.extend(options, {
            qid: response.qid,
          })
        )
      })
      .then((response) => {
        return successCallback ? successCallback(response) : response
      })
      .catch((error) => {
        if (failureCallback) return failureCallback(error)
        else throw error
      })
  },

  roadbook: function (options, successCallback, failureCallback) {
    return requestRoute(options)
      .then((response) => {
        if (!response || !response.routes || response.routes.length === 0) {
          throw new Error("no_result")
        }
        return Mappy.cors({
          url: "https://api-iti." + Mappy._getDomain() + "/multipath/" + Mappy._getRouteAPIVersion() + response.routes[0].roadbook_link,
        })
      })
      .then((response) => {
        return successCallback ? successCallback(response) : response
      })
      .catch((error) => {
        if (failureCallback) return failureCallback(error)
        else throw error
      })
  },
}
