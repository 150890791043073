"use strict"

function objectToUri(obj) {
  var data, key, value
  data = []
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      value = obj[key]
      data.push(
        window.encodeURIComponent(key) + "=" + window.encodeURIComponent(value)
      )
    }
  }
  return data.join("&")
}

var translations = {
  fr: {
    "layers.traffic": "Trafic",
    "layers.transport": "Transports en commun",
    "layers.photo": "Vue aérienne",
    "layers.hybrid": "Afficher le nom des lieux",
    "layers.bicycle": "Pistes cyclables",
    "trafficLegend.trafficAt": "Trafic à {hours}:{minutes}",
    "trafficLegend.slow": "ralenti",
    "trafficLegend.blocked": "fermé",

    "vehicle.car": "Voiture",
    "vehicle.walk": "Piéton",
    "vehicle.bike": "Vélo",
    "bikelegend.title": "Pistes cyclables",
  },
  en: {
    "layers.traffic": "Traffic",
    "layers.transport": "Public transports",
    "layers.photo": "Satellite photos",
    "layers.hybrid": "Display locations names",
    "layers.bicycle": "Bike lanes",
    "trafficLegend.trafficAt": "Traffic at {hours}:{minutes}",
    "trafficLegend.slow": "slow",
    "trafficLegend.blocked": "congested",

    "vehicle.car": "Car",
    "vehicle.walk": "Pedestrian",
    "vehicle.bike": "Bicycle",
    "bikelegend.title": "Bicycle paths",
  },
  nl: {
    "layers.traffic": "Verkeer",
    "layers.transport": "Openbaar vervoer",
    "layers.photo": "Luchtfoto's",
    "layers.hybrid": "Locaties weergeven namen",
    "layers.bicycle": "Fietspaden",
    "trafficLegend.trafficAt": "Verkeer op {hours}:{minutes}",
    "trafficLegend.slow": "langzaam",
    "trafficLegend.blocked": "vastgelopen",

    "vehicle.car": "Auto",
    "vehicle.midcar": "Middelgrote auto",
    "vehicle.walk": "Voetganger",
    "vehicle.bike": "Fiets",
    "bikelegend.title": "Fietspaden",
  },
}


module.exports = {
  version: "@@version",
  _domain: "mappy.net",
  _apikey: null,
  _locale: "fr_FR",
  _imgPath: "images/",
  _logs: true,
  _defaultRouteAPIVersion: "7.0",
  _tooltip: true,
  _logFn: undefined,
  _refreshTraffic: 120000,

  getText: function (key) {
    return translations[this._locale.substr(0, 2)][key] || ""
  },

  setLocale: function (locale) {
    if (
      locale !== "fr_FR" &&
      locale !== "en_GB" &&
      locale !== "fr_BE" &&
      locale !== "nl_BE"
    ) {
      throw new Error("This locale is not available")
    }

    this._locale = locale
    return this
  },

  setImgPath: function (path) {
    this._imgPath = path
    return this
  },

  getImgPath: function () {
    return this._imgPath
  },

  getLocale: function () {
    return this._locale
  },

  enableLogs: function () {
    this._logs = true
    return this
  },

  disableLogs: function () {
    this._logs = false
    return this
  },

  isLogsEnabled: function () {
    return this._logs
  },

  enableTooltip: function () {
    this._tooltip = true
    return this
  },

  disableTooltip: function () {
    this._tooltip = false
    return this
  },

  isTooltipEnabled: function () {
    return this._tooltip
  },

  setApikey: function (apikey) {
    this._apikey = apikey

    if (this._apikey) {
      // Ping mothership with API version and apikey
      var pingUrl = "https://log." + this._getDomain() + "/log/1.0/ping/api-leaflet/" + this._apikey + "/" + this.version
      setTimeout(function () {
        new Image().src = pingUrl
      }, 1000)
    }
  },

  _getApikey: function () {
    return this._apikey
  },

  enableHttps: function () {
    return this
  },

  disableHttps: function () {
    throw new Error('Mappy API is now only usable via HTTPS')
  },

  _getDomain: function () {
    return this._domain
  },

  _setDomain: function (domain) {
    this._domain = domain
    return this
  },

  _setLogFn: function (fn) {
    this._logFn = fn
    return this
  },

  _getLogFn: function () {
    if (this._logFn) {
      return this._logFn
    } else if (typeof console.warn === "function") {
      return console.warn
    }
    return () => { }
  },

  _getGeocodeForward: function () {
    return ("https://api-geocode." + this._getDomain() + "/forward/1.0/find")
  },

  _getGeocodeReverse: function () {
    return ("https://api-geocode." + this._getDomain() + "/reverse/1.0/find")
  },

  _getSuggest: function () {
    return ("https://api-suggest." + this._getDomain() + "/suggest/1.3/suggest")
  },

  _checkApikey: function () {
    if (!this._getApikey()) {
      throw new Error("apikey is mandatory (refer to documentation).")
    }
  },

  _setRouteAPIVersion: function (routeAPIVersion) {
    this._routeAPIVersion = routeAPIVersion
    return this
  },

  _getRouteAPIVersion: function () {
    return this._routeAPIVersion || this._defaultRouteAPIVersion
  },

  _resetRouteAPIToDefaultVersion: function () {
    this._routeAPIVersion = this._defaultRouteAPIVersion
    return this
  },

  cors: function (options) {
    const url =
      options.url + (options.data ? "?" + objectToUri(options.data) : "")

    return fetch(url, {
      headers: {
        apikey: this._apikey,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((err) => {
            throw new Error(err)
          })
        } else {
          return response.json()
        }
      })
      .then((json) => {
        if (options.checkForAPIDepreciation)
          this._checkForAPIDepreciation(json)
        return json
      })
      .catch((error) => {
        if (this._getLogFn) this._getLogFn()(error)
        if (response.status >= 400 && response.status < 500) {
          if (response.headers.get("content-type").indexOf("json") !== -1)
            throw new Error(JSON.parse(req.responseText))
          else throw new Error("no_result")
        }
        throw new Error("server_error")
      })
  },

  _checkForAPIDepreciation: function (json) {
    const OK_DEPRECATION_STATUS = "ok"

    var deprecationStatus =
      json && json.deprecation_status ? json.deprecation_status : {}

    if (
      this.isLogsEnabled() &&
      deprecationStatus.status &&
      deprecationStatus.status !== OK_DEPRECATION_STATUS
    ) {
      this._getLogFn()(deprecationStatus)
    }
  },
}
